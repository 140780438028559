import useJwt from '@/auth/jwt/useJwt'
import { API_ROUTES } from '../config/routes/api'

export default {
	async getAll(profession_id) {
		const params = {
			order: 'desc',
			order_by: 'full_name',
			offset: 0,
			limit: 0,
			'filters[0][field]': 'profession_id',
			'filters[0][operator]': '=',
			'filters[0][value]': profession_id,
		}
		const { data } = await useJwt.get(API_ROUTES.employee.get, params)

		return data
	},
}
